var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "demo-app" }, [
    _c("div", { staticClass: "demo-app-sidebar" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "demo-app-sidebar-section" }, [
        _c("label", [
          _c("input", {
            attrs: { type: "checkbox" },
            domProps: { checked: _vm.calendarOptions.weekends },
            on: { change: _vm.handleWeekendsToggle }
          }),
          _vm._v("\n        toggle weekends\n      ")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "demo-app-sidebar-section" }, [
        _c("h2", [
          _vm._v("All Events (" + _vm._s(_vm.currentEvents.length) + ")")
        ]),
        _vm._v(" "),
        _c(
          "ul",
          _vm._l(_vm.currentEvents, function(event) {
            return _c("li", { key: event.id }, [
              _c("b", [_vm._v(_vm._s(event.startStr))]),
              _vm._v(" "),
              _c("i", [_vm._v(_vm._s(event.title))])
            ])
          }),
          0
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "demo-app-main" },
      [
        _c("FullCalendar", {
          staticClass: "demo-app-calendar",
          attrs: { options: _vm.calendarOptions },
          scopedSlots: _vm._u([
            {
              key: "eventContent",
              fn: function(arg) {
                return [
                  _c("b", [_vm._v(_vm._s(arg.timeText))]),
                  _vm._v(" "),
                  _c("i", [_vm._v(_vm._s(arg.event.title))])
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "demo-app-sidebar-section" }, [
      _c("h2", [_vm._v("Instructions")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _vm._v(
            "Selecione as datas e você será solicitado a criar um novo evento"
          )
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("Arraste, solte e redimensione eventos")]),
        _vm._v(" "),
        _c("li", [_vm._v("Clique em um evento para excluí-lo")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }