
let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

let tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)
let tomorrowStr = tomorrow.toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'Evento de dia inteiro',
    start: todayStr
  },
  {
    id: createEventId(),
    title: 'Maria',
    start: todayStr + 'T12:00:00'
  },
  {
    id: createEventId(),
    title: 'Tereza',
    start: todayStr + 'T12:00:00'
  },
  {
    id: createEventId(),
    title: 'Joaquina',
    start: todayStr + 'T12:00:00'
  },
  {
    id: createEventId(),
    title: 'Chicão',
    start: todayStr + 'T13:00:00'
  },
  {
    id: createEventId(),
    title: 'Braga',
    start: todayStr + 'T13:00:00'
  },
  {
    id: createEventId(),
    title: 'Evento DeláChicones',
    start: tomorrow
  }
]

export function createEventId() {
  return String(eventGuid++)
}
